<template>
    <v-card tile height="500" width="280" class="ma-5">
        <v-container class="pa-0">
            <v-hover>
                <template v-slot:default="{ hover }">
                    <section class="pointer-cursor">
                        <v-img
                            :src="image"
                            height="500"
                            class="align-start pointer-cursor text-center"
                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                            cover
                            eager
                            transition="slide-x-transition"
                            @click="overlay = !overlay"
                            >
                            <v-card-title class="white--text course-title-div f16 d-flex justify-center align-center" style="word-break: break-word">{{ title }}</v-card-title>        
                        </v-img>
                        <v-slide-y-transition>
                            <v-overlay
                                v-if="hover"
                                absolute
                                :class="hover && 'course-box-shadow'"
                                class="white--text overflow-y-auto d-flex align-start pa-5 justify-start"
                                opacity="0"
                                zIndex="1"
                                width="250px"
                                >
                                <section v-for="(course, i) in courses" :key="i" >
                                    <v-container fluid class="course_card d-flex flex-row align-center justify-space-between" @click="$router.push({name: 'Course Learn More', params: { uuid: course.uuid}, query: { timestamp: Date.now() }})">
                                        <div class="d-flex flex-column">
                                            <div v-if="course.available_until" class="primary-font f11 py-0 text-left">
                                                <span v-if="course.available_until.start_date"> from <b class="primary-font">{{ $dateFormat.mmDDyy(course.available_until.start_date) }} </b></span>
                                                <span v-if="course.available_until.end_date"> until <b class="primary-font">{{ $dateFormat.mmDDyy(course.available_until.end_date) }} </b></span>
                                            </div>
                                            <div class="f16 fw500 text-left">
                                                {{ course.title }}
                                            </div>
                                        </div>
                                        <v-btn icon dense>
                                            <v-icon>
                                                mdi-chevron-right
                                            </v-icon>
                                        </v-btn>
                                    </v-container>
                                    <v-divider v-if="i !== courses.length - 1" class="my-1" style="border: 1px solid white;"/>
                                </section>
                            </v-overlay>
                        </v-slide-y-transition>
                    </section>
                </template>
            </v-hover>
            <!-- <v-overlay
                :value="overlay"
                absolute
                :class="overlay && 'course-box-shadow pointer-cursor'"
                @click="overlay = false"
                class="white--text overflow-y-auto d-flex align-start py-5"
                color="#282f81"
                >
                <div v-for="(course, i) in courses" :key="i" @click="$router.push({name: 'Course Learn More', params: { uuid: course.uuid}, query: { timestamp: Date.now() }})">
                    <div v-if="course.available_until" class="primary-font f11 py-0">
                        <span v-if="course.available_until.start_date"> from <b class="primary-font">{{ $dateFormat.mmDDyy(course.available_until.start_date) }} </b></span>
                        <span v-if="course.available_until.end_date"> until <b class="primary-font">{{ $dateFormat.mmDDyy(course.available_until.end_date) }} </b></span>
                    </div>
                    <div class="f16 fw500">
                        {{ course.title }}
                    </div>
                    <v-divider class="mt-5" style="border: 1px solid white;"/>
                </div> -->
                <!-- <v-container>
                    <div class="f11">
                        <span > May 9, 2023 </span>
                        <span > until August 10, 2023 </span>
                    </div> 
                    <div class="f16 fw500">
                        Test Course PCI gsdgfd gfdg
                    </div>
                    <v-divider class="mt-5" style="border: 1px solid white;"/>
                </v-container>
                <v-container>
                    <div class="f11">
                        <span > May 9, 2023 </span>
                        <span > until August 10, 2023 </span>
                    </div> 
                    <div class="f16 fw500">
                        Test Course PCI gsdgfd gfdg
                    </div>
                </v-container>
                <v-container>
                    <div class="f11">
                        <span > May 9, 2023 </span>
                        <span > until August 10, 2023 </span>
                    </div> 
                    <div class="f16 fw500">
                        Test Course PCI gsdgfd gfdg
                    </div>
                </v-container>
                <v-container>
                    <div class="f11">
                        <span > May 9, 2023 </span>
                        <span > until August 10, 2023 </span>
                    </div> 
                    <div class="f16 fw500">
                        Test Course PCI gsdgfd gfdg
                    </div>
                </v-container> -->
            <!-- </v-overlay> -->
        </v-container>
    </v-card>
</template>

<style scoped>
.course-title-div {
    background-color: rgb(40,47,129);
}
.v-overlay__content {
    width: 100% !important
}
.course_card:hover{
    background-color: rgb(30, 36, 101);
}
</style>

<script>

export default {
    props: ['image', 'title', 'courses'],
    data: () => ({
        hover: false,
        overlay: false,
    })
}
</script>