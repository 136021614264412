<template>
    <section>
        <v-img
            :src="require('../../assets/images/landing/csc-building.jpg')"
            :lazy-src="require('../../assets/images/landing/csc-building.jpg')"
            gradient="to bottom, rgba(0,0,0,.5), rgba(0,0,0,0)"
            cover
            eager
            height="95vh"
            class="fade"
        >
            <v-container>
                <v-row no-gutters class="primary-font fade">
                    <v-col cols="12" md="5">
                        <Login v-if="showLogin" @toRegister="showLogin = false" transition="fade-transition" class="my-10" :toCheckout="false"/> 
                        <Register v-else-if="!showLogin" @toLogin="showLogin = true" transition="fade-transition"/> 
                    </v-col>
                </v-row>
            </v-container>
        </v-img>

        <v-container fluid class="text-center my-10" v-if="!loading && courses.length > 0">
            <span class="f20 fw600 primary-font">PROGRAMS</span>
            <v-divider class="l-primary my-5 mx-auto" width="40%" />
            <section class="d-flex flex-wrap justify-center">
                <HomeCard class="fade" v-for="(course, i) in courses" :key="i" :image="require(`@/assets/images/landing/background/csc-course-frame${i+1}.png`)" :title="course.class_name" :courses="course.courses"/>
            </section>=
        </v-container>
    </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Login from "@/components/landing/forms/Login.vue";
import Register from "@/components/landing/forms/Register.vue";
import HomeCard from "@/components/landing/cards/home-card.vue";
import Section from '../admin/Section.vue';

export default {
    name: 'landing-home',
    components: {
        Login,
        Register,
        HomeCard,
        Section
    },
    data: () => ({
        showLogin: true,
        loading: false
    }),
    mounted() {
        this.loading = true
        this.getHomepageCourses().then(() => {
            this.loading = false
        })
    },
    methods: {
        ...mapActions(['getHomepageCourses'])
    },
    computed: {
        ...mapState({
            courses: (state) => state.other_courses
        }),
    }
}
</script>